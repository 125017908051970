<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      formFunctionCode: 'kms_on_product_form',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'remarks') {
        item.props = {
          ...item.props,
          type: 'textarea',
        };
      }
      if (item.field === 'sellPartyId') {
        item.restful = '/mdm/mdmSellPartyController/list';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'sellPartyName', value: 'id' };
        item.restfulParamsGetValue = { directSystemId: 'directSystemId' };
        item.refresh = true;
      }
      if (item.field === 'proDetailsName') {
        item.restful = '/mdm/mdmShelfProductController/findProductList';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'proDetailsName', value: 'proDetailsName' };
        item.restfulParamsGetValue = { sellPartyId: 'sellPartyId', directSystemId: 'directSystemId' };
        item.refresh = true;
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'proDetailsName',
        };
      }
      if (item.field === 'directSystemId') {
        item.restful = '/mdm/mdmDirectSystemController/directSystemSelect';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'directSystemName', value: 'directSystemId' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'directSystemName',
        };
        item.on.optionCallback = (options, value) => console.log(options, value);
      }
      if (item.field === 'orderUnitId') {
        item.restful = '/mdm/mdmShelfProductController/findProductUnit';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'orderUnit', value: 'orderUnitId' };
        item.restfulParamsGetValue = { proDetailsNumber: 'proDetailsNumber' };
        item.refresh = true;
      }
      if (item.field === 'storeProductCode') {
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.disabled(true, 'proDetailsNumber');
      this.hiddenFields(true, ['directSystemName', 'orderUnit']);
      const proDetailsName = this.getRule('proDetailsName');
      const directSystemId = this.getRule('directSystemId');
      const orderUnitId = this.getRule('orderUnitId');
      proDetailsName.on.change = (e) => {
        const selectPro = proDetailsName.options.find((item) => item.proDetailsName === e);
        this.setValue({
          proDetailsNumber: selectPro.proDetailsNumber,
        });
      };
      directSystemId.on.change = (e) => {
        const selectDirectSystemId = directSystemId.options.find((item) => item.directSystemId === e);
        this.setValue({
          directSystemName: selectDirectSystemId && selectDirectSystemId.directSystemName,
        });
      };
      orderUnitId.on.change = (e) => {
        const selectOrderUnitId = orderUnitId.options.find((item) => item.orderUnitId === e);
        this.setValue({
          orderUnit: selectOrderUnitId.orderUnit,
        });
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        // this.setValue(
        //   {
        //     ...this.formConfig.row,
        //   },

        // );
        request.post('/mdm/mdmShelfProductController/query', {
          id: this.formConfig.row.id,
          directSystemId: this.formConfig.row.directSystemId,
          directSystemName: this.formConfig.row.directSystemName,
        }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/mdm/mdmShelfProductController/save';
        const params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmShelfProductController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
