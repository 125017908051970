import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';
import Form from '../form';

export default {
  extends: TablePage,
  props: {
    treeCode: String,
    treeName: String,
    // bsDirectId: String,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmShelfProductController/page',
      params: {
        directSystemId: '',
        directSystemName: '',
        // bsDirectId: '',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
  },
  watch: {
    treeCode(val) {
      this.params.directSystemId = val;
      this.params.directSystemName = this.treeName;
      // this.params.bsDirectId = this.bsDirectId;
      this.getList();
    },
  },
  created() {
    this.getConfigList('kms_on_product_table');
  },
  methods: {
    beforeGetList() {
      if (this.params.directSystemId) {
        return true;
      }
      return false;
    },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      // if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
      //   this.resetTree();
      //   return false;
      // }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
        };
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '500px';
        this.openModal();
      } else if (val.code === 'edit') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
          row,
        };
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '500px';
        this.openModal();
      } else if (val.code === 'view') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
          row,
          code: 'view',
        };
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '500px';
        this.openModal();
      }
    },
  },
};
